/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: LoginContext.js
# Description: This is the Authentication provider, this file contains different variables, functions, API calls that use the auth section/layout
               to provide the authentication, credential recovery, account creation, MFA, and more.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/23/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { helpHttp } from "helpers/helpHttp";
import { createContext, useContext, useState } from "react";
import NotificationContext from "context/NotificationContext";
import LoadingContext from "context/LoadingContext";
import { useHistory } from "react-router";

import { useAuth } from "hooks/useAuth";

const LoginContext = createContext();

const LoginProvider = ({children}) => {

    const { setMessage, setStatus, setType } = useContext(NotificationContext);
    const { setLoading } = useContext(LoadingContext);
    const { setAuth, getBearerAcessToken, getRefreshToken, getNameUser, parseJwt, setPrelogin} = useAuth();
    const history = useHistory();
    const { REACT_APP_API_URL } = process.env;
    const { REACT_APP_ENV } = process.env;
    
    let api = helpHttp();
    let url = REACT_APP_API_URL;
    const env = REACT_APP_ENV;

    // States for challenge parameters
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [sub, setSub] = useState('');
    const [session, setSession] = useState('');
    const [mfaSecretCode, setMfaSecretCode] = useState('');
    const [temptToken, setTemptToken] = useState('');
    const [temptJwtToken, setTemptJwtToken] = useState('');
    

    // stastes for NewPassword modal
    const [viewModalNewPassword, setViewModalNewPassword] = useState();

    // stastes for NewPasswordReq modal
    const [viewModalNewPasswordReq, setViewModalNewPasswordReq] = useState();

    // stastes for Setup/validate modal
    const [viewModalSetupAccount, setViewModalSetupAccount] = useState();

    // stastes for MFA_SETUP modal
    const [viewModalMfaSetup, setViewModalMfaSetup] = useState();

    // stastes for SELECT_MFA_TYPE modal
    const [viewModalSelectMfaType, setViewModalSelectMfaType] = useState();

    //states for MFA by SMS and SoftwareToken
    const [viewModalSmsRac, setViewModalSmsRac] = useState();
    const [viewModalStRac, setViewModalStRac] = useState();
    const [viewModalStSetup, setViewModalStSetup] = useState();
    const [viewModalStSetupFt, setViewModalStSetupFt] = useState();

    // Open and close NewPassword modal functions
    const loadModalNewPassword = (email) => {
        setEmail(email)
        setViewModalNewPassword(true);
    }
    const closeModalNewPassword = () => {
        setViewModalNewPassword(false);
    }

    // Open and close NewPasswordReq modal functions
    const loadModalNewPasswordReq = (sub, session) => {
        setSub(sub)
        setSession(session)
        setViewModalNewPasswordReq(true);
    }
    const closeModalNewPasswordReq = () => {
        setViewModalNewPasswordReq(false);
    }
    
    // Open and close Setup/validate modal functions
    const loadModalSetupAccount = (email) => {
        setEmail(email)
        setViewModalSetupAccount(true);
    }
    const closeModalSetupAccount = () => {
        setViewModalSetupAccount(false);
    }

    // Open and close MFA_SETUP modal functions
    const loadModalMfaSetup = (email, session) => {
        setEmail(email)
        setSession(session)
        setViewModalMfaSetup(true);
    }
    const closeModalMfaSetup = () => {
        setViewModalMfaSetup(false);
    }

    // Open and close SELECT_MFA_TYPE modal functions
    const loadModalSelectMfaType = (sub) => {
        setSub(sub);
        setViewModalSelectMfaType(true);
    }
    const closeModalSelectMfaType = () => {
        setViewModalSelectMfaType(false);
    }

    // Open and close SmsRac modal functions
    const loadModalSmsRac = (sub, session) => {
        setSub(sub)
        setSession(session)
        setViewModalSmsRac(true);
    }
    const closeModalSmsRac = () => {
        setViewModalSmsRac(false);
    }

    // Open and close StRac modal functions
    const loadModalStRac = (sub, session) => {
        setSub(sub)
        setSession(session)
        setViewModalStRac(true);
    }
    const closeModalStRac = () => {
        setViewModalStRac(false);
    }

    // Open and close StRac modal functions
    const loadModalStSetup = (secertCode) => {    
        setMfaSecretCode(secertCode);
        setViewModalStSetup(true);
    }
    const closeModalStSetup = () => {
        setViewModalStSetup(false);
    }
    const loadModalStSetupFt = (secertCode, session) => {    
        setMfaSecretCode(secertCode);
        setSession(session);
        setViewModalStSetupFt(true);
    }
    const closeModalStSetupFt = () => {
        setViewModalStSetupFt(false);
    }

    // Login
    const authenticated = (token) => {
        setLoading(true)
        localStorage.setItem("token_hensall", JSON.stringify(token));
        history.push('/admin/app/');
        setAuth(true);
        setPrelogin(true);
    }
    // Token refresh
    const refreshAuthenticated = (token) => {
        localStorage.setItem("token_hensall", JSON.stringify(token));
        history.go(0)
        setAuth(true);
    }

    // Login request
    const login = (data) => {
        setLoading(true);
        let endpoint = url +"auth/login";
        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                if (res.access_token){
                    if (env !== "DEVELOPMENT") {
                        loadModalMfaSetup(data.email);
                        const parsedToken = parseJwt(res.access_token)
                        if (parsedToken.token.AuthenticationResult.AccessToken) {
                            setTemptToken(parsedToken.token.AuthenticationResult.AccessToken);
                            setTemptJwtToken(parsedToken.token_jwt);
                            setEmail(parsedToken.sub)
                        } else {
                            setType("danger");
                            setMessage("You had typed your email or password incorrectly. Please, correct them and try again.");
                            setStatus(1);
                        }
                    } else {
                        authenticated(res.access_token);
                    }
                } else if(res.ChallengeName) {
                    if (res.ChallengeName === "NEW_PASSWORD_REQUIRED"){
                        loadModalNewPasswordReq(res.ChallengeParameters.USER_ID_FOR_SRP, res.Session)
                        return
                    } else if (res.ChallengeName === "MFA_SETUP"){
                        loadModalMfaSetup(data.email, res.Session, false)
                    } else if (res.ChallengeName === "SELECT_MFA_TYPE"){
                        loadModalSelectMfaType(res.ChallengeParameters.USER_ID_FOR_SRP)
                    } else if (res.ChallengeName === "SMS_MFA"){
                        loadModalSmsRac(res.ChallengeParameters.USER_ID_FOR_SRP, res.Session)
                    }else if (res.ChallengeName === "SOFTWARE_TOKEN_MFA"){
                        loadModalStRac(res.ChallengeParameters.USER_ID_FOR_SRP, res.Session)
                    }
                } else {
                    setType("danger");
                    setMessage("Currently the service is presenting interruptions. Try again later, if error persists please send an email to HensallLoginSupport@hdc.on.ca with subject: 'Service interruption, Error OL-01'.");
                    setStatus(1);
                }
            } else {
                res.body.then((err) => {
                    if (err?.msg && err.msg == "User Disabled"){
                        setType("danger");
                        setMessage("This user has been disabled. Please contact an administrator or send an email to HensallLoginSupport@hdc.on.ca for further assistance.");
                        setStatus(1);
                    } else {
                        setType("danger");
                        setMessage("You had typed your email or password incorrectly. Please, correct them and try again.");
                        setStatus(1);
                    }
                });
            }
            setLoading(false);
        })
    };

    // Refresh Token
    const refreshToken = () => {
        const refresh = getRefreshToken()
        const email = getNameUser();

        if (!refresh) {
            setLoading(false)
            setType("danger");
            setMessage("The data was already updated. For you to see the form changes, please, log out and in to the app again.");
            setStatus(1);
           return
        }

        let endpoint = url +"auth/refresh_token";
        let options = {
                body: {
                    email: email,
                    refresh_token: refresh
                },
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                refreshAuthenticated(res.access_token)
                setType("success");
                setMessage("Saved");
                setStatus(1);
            }else{
                setLoading(false)
                setType("danger");
                setMessage("The data was already updated. For you to see the form changes, please, log out and in to the app again.");
                setStatus(1);
            }
        }).catch((err) => {
            if (err) {
                setLoading(false)
                setType("danger");
                setMessage("The data was already updated. For you to see the form changes, please, log out and in to the app again.");
                setStatus(1);
            }
        })
    }

    // New password methods
    const forgotPassword = (data) => {
        let endpoint = url +"auth/forgot_password";
        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setLoading(false);
                loadModalNewPassword(data.email)
            }else{
                setLoading(false);
                setType("danger");
                setMessage("The number of attempts was exceeded. Please, try agin later");
                setStatus(1);
            }
            setLoading(false);
            return false;
        })
    };

    const newPassword = (data) => {
        setLoading(true);
        let endpoint = url +"auth/confirm_forgot_password";
        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setLoading(false);
                history.push('/auth/login');
                setType("success");
                setMessage("New password saved");
                setStatus(1);
            }else{
                setType("danger");
                setMessage("Error verification code missmatch");
                setStatus(1);
            }
            setLoading(false);
        }) 
        return null
    };

    const newPasswordReq = (data) => {
        setLoading(true);
        let endpoint = url +"auth/respond_to_auth_challenge";

        data.challenge = "NEW_PASSWORD_REQUIRED"
        data.session = session
        data.username = sub
        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setLoading(false);
                closeModalNewPasswordReq()
                setType("success");
                setMessage("New password saved");
                setStatus(1);
            }else{
                setType("danger");
                setMessage("Error verification code missmatch");
                setStatus(1);
            }
            setLoading(false);
        }) 
        return null
    };

    // MFA PReference
    const mfaPreference = (data) => {
        setLoading(true);
        let endpoint = url +"auth/mfa_preference";
        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setLoading(false);
                setType("success");
                setMessage("Preference Saved");
                setStatus(1);
                window.location.reload();
            }else{
                setLoading(false);
                setType("danger");
                setMessage("Error couldn't update attribute");
                setStatus(1);
            }
            return false;
        })
    }
    
    // Phone update method
    const updatePhone = (data, token) => {
        setLoading(true);
        data.attributes = "phone_number;" + data.phone
        data.email = email
        let endpoint = url +"users/update_attribute";
        let options = {
            body: data,
            headers: {
                "content-type":"application/json",
                "Authorization": "Bearer "+ token,
            },
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                closeModalMfaSetup();
                const newData = {
                    email: email,
                    smsEnable: "true",
                    smsPreferred: "true",
                    softwareEnable: "",
                    softwarePreferred: ""
                }
                
                setType("success");
                setMessage("Phone number saved");
                setStatus(1);
                mfaPreference(newData)
                return
            }else{
                setLoading(false);
                setType("danger");
                setMessage("Error couldn't update attribute");
                setStatus(1);
            }
            return false;
        })
    }

    // Email verification methods
    const checkUserExists = (data) => {
        setLoading(true);
        let endpoint = url +"users_management/check_email";
        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setLoading(false);
                return forgotPassword(data);
            } else if (res.status === 403){
                res.body.then(res => {
                    if (res.message) { console.log(res.message) }
                    setLoading(false);
                    setType("danger");
                    setMessage("You had used an invalid email. Please try a valid email.");
                    setStatus(1);
                })
            } else if (res.status === 400){
                setLoading(false);
                loadModalSetupAccount(data.email);
            }
            return false;
        })
    }

    const checkEmailExistence = (data) => {
        setLoading(true);
        let endpoint = url +"users_management/exist_user";
        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                return forgotPassword(data);
            }else{
                setLoading(false);
                setType("danger");
                setMessage("You must type an existing email associated with a user. Please type an existing email and try again, or create an account if needed.");
                setStatus(1);
            }
            return false;
        })
    }

    const sendEmail = (data) => {
        setLoading(true);
        let endpoint = url +"users/send_email";
        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setLoading(false);
                closeModalSetupAccount();
                history.push('/auth/login/');
                setType("success");
                setMessage("Email sent to Admin team");
                setStatus(1);
            }else{
                setLoading(false);
                setType("danger");
                setMessage("Currently the service is presenting interruptions. Try again later, if error persists please send an email to HensallLoginSupport@hdc.on.ca with subject: 'Service interruption Error OL-02'.");
                setStatus(1);
            }
            return false;
        })
    }

    // Respond to Auth challenge methods
    const smsRac = (data) => {
        setLoading(true);
        let endpoint = url +"auth/respond_to_auth_challenge";

        data.challenge = 'SMS_MFA'
        data.username = sub
        data.session = session

        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setLoading(false);
                if(res.access_token){
                    authenticated(res.access_token)
                    return;
                }
            }else{
                setLoading(false);
                setType("danger");
                setMessage("You must type a valid code that you have received in your smartphone. Please, write it in the required field or ask for a new valid code.");
                setStatus(1);
            }
            return false;
        })
    }

    const stRac = (data) => {
        setLoading(true);
        let endpoint = url +"auth/respond_to_auth_challenge";

        data.challenge = 'SOFTWARE_TOKEN_MFA'
        data.username = sub
        data.session = session

        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setLoading(false);
                if(res.access_token){
                    authenticated(res.access_token)
                    return;
                }
            }else{
                res.body.then((re)=>{
                    if (re.msg.trim() === "NotAuthorizedException") {
                        setLoading(false);
                        setType("danger");
                        setMessage("Your session has expired. Please, cancel the process and try again");
                        setStatus(1);
                    }else if (re.msg.trim() === "CodeMismatchExeption") {
                        setLoading(false);
                        setType("danger");
                        setMessage("You must type a valid code that you have received in your authentication app. Please, try again.");
                        setStatus(1);
                    } else {
                        setLoading(false);
                        setType("danger");
                        setMessage("Unexpected error. Please try again. If error persist contact an administrator");
                        setStatus(1);
                    }
                })
            }
            return false;
        })
    }

    const SelectMfaTypeRac = (data) => {
        setLoading(true);
        let endpoint = url +"auth/respond_to_auth_challenge";

        data.challenge = 'SELECT_MFA_TYPE'
        data.username = sub

        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setLoading(false);
                if (res.ResponseMetadata.HTTPStatusCode === 200) {
                    closeModalSelectMfaType();
                    setType("success");
                    setMessage("Preference saved!");
                    setStatus(1);
                }
            }else{
                setLoading(false);
                setType("danger");
                setMessage("Error");
                setStatus(1);
            }
            return false;
        })
    }

    // MFA by software first time
    const stSetupFt = () => {
        setLoading(true);
        let endpoint = url +"auth/software_token_ft";
        
        const data = {session: session}
        
        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setLoading(false);
                if(res.ResponseMetadata.HTTPStatusCode == 200 && res.SecretCode){
                    loadModalStSetupFt(res.SecretCode, res.Session)
                    return;
                }else{
                    setType("danger");
                    setMessage("Error");
                    setStatus(1);
                    return;
                }
            }else{
                setLoading(false);
                setType("danger");
                setMessage("Error");
                setStatus(1);
            }
            return false;
        })
    }

    const stSetup = (token) => {
        setLoading(true);
        let endpoint = url +"auth/software_token";                
        const data = {accessToken: token ? token : getBearerAcessToken()}
        
        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setLoading(false);
                if(res.ResponseMetadata.HTTPStatusCode == 200 && res.SecretCode){
                    loadModalStSetup(res.SecretCode)
                    return;
                }else{
                    setType("danger");
                    setMessage("Error");
                    setStatus(1);
                    return;
                }
            }else{
                setLoading(false);
                setType("danger");
                setMessage("Error");
                setStatus(1);
            }
            return false;
        })
    }

    const stValidateSetupFt = (data) => {
        setLoading(true);
        let endpoint = url +"auth/verify_token_ft";

        data.session = session;

        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                if(res.ResponseMetadata.HTTPStatusCode == 200 && res.Status === "SUCCESS"){
                    closeModalStSetupFt();

                    const newData = {
                        email: email,
                        smsEnable: "",
                        smsPreferred: "",
                        softwareEnable: "true",
                        softwarePreferred: "true"
                    }
                    
                    setType("success");
                    setMessage("MFA by software configured successfully");
                    setStatus(1);
    
                    mfaPreference(newData)
                    window.location.reload();
                    return;
                }else{
                    setLoading(false);
                    setType("danger");
                    setMessage("You must type a valid code that you have received in your smartphone or computer. Please, write it in the required field or ask for a new valid code.");
                    setStatus(1);
                    return;
                }
            }else{
                setLoading(false);
                setType("danger");
                setMessage("You must type a valid code that you have received in your smartphone or computer. Please, write it in the required field or ask for a new valid code.");
                setStatus(1);
            }
            return false;
        })
    }

    const stValidateSetup = (data) => {
        setLoading(true);
        let endpoint = url +"auth/verify_token";

        data.accessToken = temptToken ? temptToken : getBearerAcessToken();

        let options = {
            body: data,
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                if(res.ResponseMetadata.HTTPStatusCode == 200 && res.Status === "SUCCESS"){
                    const newData = {
                        email: getNameUser(),
                        smsEnable: "",
                        smsPreferred: "",
                        softwareEnable: "true",
                        softwarePreferred: "true"
                    }

                    setLoading(false);
                    closeModalStSetup();
                    setType("success");
                    setMessage("MFA by software configured successfully");
                    setStatus(1);

                    if (email) {
                        newData.email = email
                        mfaPreference(newData);
                    } else {
                        mfaPreference(newData);
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000)
                    }
                    return;
                }else{
                    setLoading(false);
                    setType("danger");
                    setMessage("You must type a valid code that you have received in your smartphone or computer. Please, write it in the required field or ask for a new valid code.");
                    setStatus(1);
                    return;
                }
            }else{
                setLoading(false);
                setType("danger");
                setMessage("You must type a valid code that you have received in your smartphone or computer. Please, write it in the required field or ask for a new valid code.");
                setStatus(1);
            }
            return false;
        })
    }

    const data = { login,
                   email, sub, session, phone, setPhone, mfaSecretCode, temptToken, temptJwtToken,
                   newPassword, newPasswordReq, checkUserExists, checkEmailExistence,
                   viewModalNewPassword, closeModalNewPassword, loadModalNewPassword,
                   viewModalNewPasswordReq, closeModalNewPasswordReq, loadModalNewPasswordReq,
                   sendEmail, viewModalSetupAccount, closeModalSetupAccount,
                   updatePhone, viewModalMfaSetup, closeModalMfaSetup,
                   stSetup, stValidateSetup, viewModalStSetup, closeModalStSetup, loadModalStSetup,
                   stSetupFt, stValidateSetupFt, viewModalStSetupFt, closeModalStSetupFt, loadModalStSetupFt,
                   SelectMfaTypeRac, closeModalSelectMfaType, viewModalSelectMfaType,
                   smsRac, closeModalSmsRac, viewModalSmsRac,
                   stRac, closeModalStRac, viewModalStRac};

    return <LoginContext.Provider value={data}>{children}</LoginContext.Provider>;
}

export { LoginProvider };
export default LoginContext;