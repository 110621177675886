/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: helpHttp.js
# Description: This helper file creates the http-request base structure and provides functions per http method using a customized Fetch. This methods are used in all contexts.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/20/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 02/09/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import authService from "services/auth";

export const helpHttp = () => {

    const customFecth = (endpoint, options) => {

        if(!options.login){
            authService.checkTokenExpiry();
        }

        const deaultHeader = {
            accept: "application/json"
        }

        let user = authService.getCurrentUser()

        if (user === JSON.parse(localStorage.getItem("token_hensall"))){
            user = null;
        }
        const authorizationHeader = {
            Authorization : options.headers?.Authorization ? options.headers.Authorization : "Bearer " + user
        }

        const controller = new AbortController();
        options.signal = controller.signal;

        options.method = options.method || "GET";
        options.headers = options.headers ? {...deaultHeader, ...options.headers} : deaultHeader;

        if(!options.bearer){
            options.headers = {...options.headers, ...authorizationHeader};
        }else {
            options.headers = {Authorization:("Bearer " + options.bearer), ...options.headers};
        }

        if (options.formData) {
            // Use FormData directly if file upload is needed
            options.body = new FormData();
            options.body.append('file', options.formData);
            delete options.headers['Content-Type']; // Let the browser set it automatically for FormData
        } else {
            options.body = JSON.stringify(options.body) || false;
            if (!options.body) delete options.body;
        }

        options.maxContentLength = Infinity
        options.maxBodyLength = Infinity

        setTimeout(() => controller.abort(), 10000);

        return fetch(endpoint, options)
        .then((res) => 
            res.ok ? res.json()
            :Promise.reject({
                err: true,
                body: res.json(),
                status: res.status || "00",
                statusText: res.statusText || "Ocurrio un Error"
            })
        )
        .catch(err=> Object({
                err: true,
                body: err.body,
                status: "00",
                statusText: "Ocurrio un Error"
            })
        )

    }
    const get = (url, options = {}) => {
        return customFecth(url, options);
    }
    const post = (url, options = {}) => {
        options.method = "POST";
        return customFecth(url, options);
    }
    const put = (url, options = {}) => {
        options.method = "PUT";
        return customFecth(url, options);
    }
    const del = (url, options = {}) => {
        options.method = "DELETE";
        return customFecth(url, options);
    }
    return {
        get, post, put, del
    }
}