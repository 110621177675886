/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: SystemsContext.js
# Description: This is the UAM systems context, it contains API calls, variables and logic needed in systems section in the UAM.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/21/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 01/24/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { helpHttp } from "helpers/helpHttp";
import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { TYPES } from "actions/genericAction";
import { genericReducer, genericInitialState } from "../reducers/genericReducer";
import NotificationContext from "context/NotificationContext";
import LoadingContext from "context/LoadingContext";
import { useHistory } from "react-router";
import { useAuth } from "hooks/useAuth";

const SystemsContext = createContext();

const SystemsProvider = ({children}) => {

    const { getNameUser } = useAuth();

    const [toDetail, setToDetail] = useState();
    const [toUpdate, setToUpdate] = useState();
    const [detail, setDetail] = useState({});
    const [module, setModule] = useState();

    const [roles, setRoles] = useState([]);
    const [systemRoles, setSystemRoles] = useState([]);

    const [toLog, setToLog] = useState();
    
    const [viewModal, setViewModal] = useState();
    const [viewModalRoles, setViewModalRoles] = useState();

    const [typeSystem, setTypeSystem] = useState([{"id":"JWT","text":"JWT"},{"id":"TOKEN","text":"TOKEN"}]);
    
    const history = useHistory();

    const { setMessage, setStatus, setType } = useContext(NotificationContext);
    const { setLoading } = useContext(LoadingContext);
    const { REACT_APP_API_URL } = process.env;
    const [state, dispatch] = useReducer(genericReducer, genericInitialState);
    const { db } = state;

    let api = helpHttp();
    let url = REACT_APP_API_URL+"systems";
    let urlFetch = REACT_APP_API_URL+"systems";

    useEffect(() => {
        fetchData();
    },[]);

    useEffect(() => {
        if(toUpdate && toUpdate != 0){
            fetchDataDetail();
            fetchDataSystemRoles();
        }
    },[toUpdate]);

    useEffect(() => {
        if(viewModalRoles){
            fetchDataRoles();
        }
    },[viewModalRoles])

    useEffect(() => {
        if(toLog){
            //fetchDataLogs("88954b41-ffb4-424b-99aa-0c7bb42410de");
            fetchDataLogs(toLog);
        }
    },[toLog]);

    const fetchData = () => {
        setLoading(true);
        url = REACT_APP_API_URL+"admins/current/systems";
        api.get(url).then((res) => {
            if(!res.err){
                if (res) {
                    dispatch({ type: TYPES.READ_ALL_DATA, payload: res });
                }
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setType('danger')
            setMessage("Can't load data")
            setStatus(1)
        });
    };

    const fetchDataDetail = () => {
        setLoading(true);
        let endpoint = url+"/"+toUpdate;
        api.get(endpoint).then((res) => {
            setDetail(res);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
        });
    };

    const fetchDataSystemRoles = () => {
        urlFetch = REACT_APP_API_URL+"systems/"+toUpdate+"/roles";
        api.get(urlFetch).then((res) => {
            setSystemRoles(res);
        });
    };

    const fetchDataRoles = () => {
        urlFetch = REACT_APP_API_URL+"roles";
        api.get(urlFetch).then((res) => {
            var data = res.map(function (obj) {
                obj.text = obj.text || obj.description;
                return obj;
            });
            setRoles(data);
        });
    };

    const saveData = (data) => {
        setLoading(true);
        let endpoint = url;
        let newData = data;
        delete newData.id;
        if (!newData.api) {
            newData.api = ""
        }
        let options = {
            body: newData,
            headers: {"content-type":"application/json"}
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                dispatch({ type: TYPES.CREATE_DATA, payload: res });
                history.push('/admin/applications');
                setType("success");
                setMessage("The registry was created correctly");
                setStatus(1);
            }else{
                setType("danger");
                setMessage("An error occured. Please try again");
                setStatus(1);
            }
            setLoading(false);
        })
    }

    const updateData = (data) => {
        setLoading(true);
        let endpoint = url+"/"+data.id;
        let newData = data;
        delete newData.id;
        if(!newData.token_structure){
            newData.token_structure = "";
        }
        let options = {
            body: newData,
            headers: {"content-type":"application/json"}
        }
        api.put(endpoint, options).then((res) => {
            if(!res.err){
                setDetail(res);
                dispatch({ type: TYPES.UPDATE_DATA, payload: res });
                history.push('/admin/applications');
                setType("success");
                setMessage("The registry was updated correctly");
                setStatus(1);
            }else{

            }
            setLoading(false);
        })
    }

    const enableDisable = (data) => {
        setLoading(true);
        let endpoint = url+"/"+data.id+"/"+ (data.status ? 'disable' : 'enable');
        let options = {
            body: null,
            headers: {"content-type":"application/json"}
        }
        api.put(endpoint, options).then((res) => {
            if(!res.err){
                dispatch({ type: TYPES.UPDATE_DATA, payload: res });
                setType("success");
                setMessage("The registry was updated correctly");
                setStatus(1);
            }else{

            }
            setLoading(false);
        })
    }

    const deleteData = (id) => {
        setLoading(true);
        let endpoint = url+"/"+id;
        let options = {
            body: "",
            headers: {"content-type":"application/json"}
        }
        api.del(endpoint, options).then((res) => {
            if(!res.err){
                dispatch({ type: TYPES.DELETE_DATA, payload: id });
                setType("danger");
                setMessage("The registry was deleted correctly");
                setStatus(1);
            }
            setLoading(false);
        });
    }

    const addRoles = (data) => {
        setLoading(true);
        let exist = false;
        systemRoles.map((el) => {
            if(el.description === data.description){
                exist = true;
                return;
            }
        });
        if(exist){
            setType("danger");
            setMessage("This role already exists for this application. Please, verify the information.");
            setStatus(1);
            setLoading(false);
            return;
        }else{
            let endpoint = REACT_APP_API_URL+'roles';
            
            let options = {
                body: data,
                headers: {"content-type":"application/json"}
            }
            api.post(endpoint, options).then((res) => {
                if(!res.err){
                    let endpoint = REACT_APP_API_URL+"systems/"+toUpdate+"/permissions/"+res.id;
                    let options = {
                        headers: {"content-type":"application/json"}
                    }
                    api.post(endpoint, options).then((res) => {
                        if(!res.err){
                            // history.push('/admin/applications');
                            fetchDataSystemRoles();
                            setType("success");
                            setMessage("The record has been successfully saved");
                            setStatus(1);
                        }else{
                            setType("danger");
                            setMessage("The role could not get assigned to the application");
                            setStatus(1);
                        }
                    })
                }else{
                    setType("danger");
                    setMessage("Registry not created, it may already exist");
                    setStatus(1);
                }
                setLoading(false);
            })
        }
    }

    const deleteRoles = (id) => {
        // let endpoint = REACT_APP_API_URL+"systems/"+toUpdate+"/permissions/"+id;
        let endpoint = REACT_APP_API_URL+"roles/"+id;
        let options = {
            body: "",
            headers: {"content-type":"application/json"}
        }
        api.del(endpoint, options).then((res) => {
            if(!res.err){
                let newData = systemRoles.filter((el) => el.id !== id);
                setSystemRoles(newData);
                setType("danger");
                setMessage("The registry was deleted correctly");
                setStatus(1);
            }
        });
    }

    const fetchDataLogs = (id) => {
        setLoading(true);
        let endpoint = REACT_APP_API_URL+"health/logs/"+id;
        api.get(endpoint).then((res) => {
            setDetail(res);
            setLoading(false);
        }).catch((err) => {
            console.log("error");
        });
    }

    const fetchDataLogLines = (lines) => {
        setLoading(true);
        let endpoint = REACT_APP_API_URL+"health/logs/"+toLog+"/"+lines;
        api.get(endpoint).then((res) => {
            setDetail(res);
            setLoading(false);
        }).catch((err) => {
            console.log("error");
        });
    }

    const uploadImage = (id, file) => {
        // Validation for allowed file types
        const allowedTypes = ['image/jpeg', 'image/png'];

        if (!allowedTypes.includes(file.type)) {
            setType("danger");
            setMessage("The image format is not supported. Only JPEG and PNG formats are allowed. Please try again");
            setStatus(1);
            return;
        }

        setLoading(true);
        let endpoint = url + '/' + id + "/image/hco-upload";

        const formData = new FormData();
        formData.append('file', file);

        let options = {
            body: formData,
            formData: file
        }

        api.post(endpoint, options).then((res) => {
            if (!res.err) {
                setDetail({})
                setDetail(res)
                setType("success");
                setMessage("The file has been successfully saved");
                setStatus(1);
            } else {
                setType("danger");
                setMessage("The file could not be saved, please try again.");
                setStatus(1);
            }
            setLoading(false);
        }).catch((error) => {
            setType("danger");
            setMessage("There was an error uploading the file. Please try again");
            setStatus(1);
            setLoading(false);
        });
    }

    const data = {db, detail, setToDetail, setToUpdate, updateData, enableDisable, saveData, deleteData, module, 
        setModule, setDetail, viewModal, setViewModal, viewModalRoles, setViewModalRoles, 
        roles, systemRoles, addRoles, deleteRoles, typeSystem, setToLog, fetchDataLogLines, uploadImage };

    return <SystemsContext.Provider value={data}>{children}</SystemsContext.Provider>;
}

export { SystemsProvider };
export default SystemsContext;