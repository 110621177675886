/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: App.jsx
# Description: This is the home component containing the application cards defining styles, images, and redirection triggers per card.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/26/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 01/05/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState, useRef} from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    CardTitle,
  } from "reactstrap";

import Users from "./Users.jsx";
import AddUser from "./AddUser";
import AppHeader from "components/Headers/AppHeader.js";
import AppContext from "context/AppContext";
import LoadingContext from "context/LoadingContext";

import { useAuth } from "hooks/useAuth";
import AppFooter from "components/Footers/AppFooter";
import "../../../assets/css/custom/app-card.css"
import Login from "../login/Login.jsx";

  const App = ( ) => {

    const { selectApp, systems, setSystems, fetchApplicationList } = useContext(AppContext);

    const { getSystems, getCurrentUserApp, checkTokenExpiry } = useAuth();

    const { setLoading } = useContext(LoadingContext);

    const[state, setState] = useState({});

    let flag = false;

    useEffect(() => {
      setLoading(true);
      // setSystems(getSystems()); 
      // fetchApplicationList();
    }, []);

    useEffect(() => {
      if(localStorage.getItem("token_hensall_login")){
        if (checkTokenExpiry()) return;
      } else if (systems){
        systems.map((prop) => {
          if (prop.acronym == "hlogin") {
            selectApp(prop.type, prop.acronym, getCurrentUserApp(), prop.url, prop.token_structure);
          }
        })
      }

      if(systems.length > 0 && !flag){
        systems.map((prop) => {
          if (prop.status == false) {
            confirmAlert();
            flag = true;
          }
        })
      }
    }, [systems]);

    const handleApp = (e, type, acronym, token, url, structure) => {
      e.preventDefault();
      selectApp(type, acronym, token, url, structure);
    }

    const arraySort = () => {
      systems.sort((a, b) => {
        let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();

        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
      })
    }

    const cardStyle = (acronym) => {
      if (acronym === "hlogin"){
        return { display: "none" }
      } else {
        return { margin:"0.8em", padding: "0", minWidth: "190px", maxWidth: "220px", cursor: "pointer" }
      }
    }

    const imgStyle = (acronym) => {
      // if (acronym === "hlogin"){
      //   return { height: "120px", margin: "0.5em 0" }
      // } else if (acronym === "fieldtrace"){
      //   return { height: "110px", margin: "1em 0" }
      // } else if (acronym === "cis"){
      //   return { height: "100px", margin: "1.5em 0" }
      // } else if (acronym === "tenergy"){
      //   return { height: "100px", margin: "1.5em 0" }
      // } else if (acronym === "SageMem"){
      //   return { height: "70px", margin: "2.25em 0" }
      // } else if (acronym === "seeds"){
      //   return { height: "120px", margin: "1.25em 0" }
      // } else if (acronym === "intserv" || acronym === 'integration'){
      //   return { height: "120px", margin: "2em 0 0.75em 0" }
      // } else {
        return { height: "120px", margin: "1.2em 0" }
      // }
    }

    const cardImg = (acronym) => {
      // if (acronym === "hlogin"){
      //   return require("assets/img/icons/cards/UAM-logo.png").default
      // } else if (acronym === "fieldtrace"){
      //   return require("assets/img/icons/cards/fieldtrace.svg").default
      // } else if (acronym === "cis"){
      //   return require("assets/img/icons/cards/CIS-logo.png").default
      // } else if (acronym === "tenergy"){
      //   return require("assets/img/icons/cards/energy-logo.png").default
      // } else if (acronym === "ar" || acronym === "armi"){
      //   return require("assets/img/icons/cards/ar-logo.png").default
      // } else if (acronym === "SageMem"){
      //   return require("assets/img/icons/cards/sage-logo.svg").default
      // } else if (acronym === "seeds"){
      //   return require("assets/img/icons/cards/seeds-logo.png").default
      // } else if (acronym === "intserv" || acronym === 'integration'){
      //   return require("assets/img/icons/cards/integration-logo.svg").default
      // } else {
        return require("assets/img/icons/cards/app-logo.jpg").default 
      // }
    }

    const hideAlert = () => {
      setState({
        alert: null
      });
    };

    const confirmAlert = () => {
      setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Under Maintenance!"
            onConfirm={() => hideAlert()}
            confirmBtnBsStyle="hensall"
            confirmBtnText="Ok"
            btnSize=""
          >
            <p>One or more Applications are currently under maintenance. This usually takes between 30 minutes to 1 hour.</p>
            <p>Please refresh the page to resume your work. If you have any issues logging in to the Applications, please contact <b>HensallLoginSupport@hdc.on.ca</b></p>
          </ReactBSAlert>
        )
      });
    };

    return (
      <>
        {state.alert}
        <Row 
          className="justify-content-center m-0 pt-5" 
          style={{
            position: "static",
            width: "100%",
            minHeight: "100%",
            backgroundImage:
              'url("' +
              require("assets/img/theme/OL-Background-Q10.jpg").default +
              '")',
            filter: "opacity(0.87)",
            backgroundSize: "cover",
            backgroundPosition: "center top",
            backgroundAttachment: "fixed",
            zIndex: "-1000",
          }}>
          <Col className="card-wrapper" xs="11" sm="11" md="10" xl="9">
            <Card style={ { backgroundColor: "#ddd"} }>
              <CardHeader>
                <h3 className="mb-0">Select the Application</h3>
              </CardHeader>
              <CardBody>
                <div className="card-columns d-flex justify-content-center row px-2">
                  {arraySort()}
                  { systems ? (systems.map((prop, key) => {
                      return(
                        <Card className={prop.status ? "col app-card" : "col app-card-disabled"}
                          key={prop.name}
                          style={cardStyle(prop.acronym)}
                          onClick={e => {
                            e.preventDefault();
                            handleApp(e, prop.type, prop.acronym, getCurrentUserApp(), prop.url, prop.token_structure);
                          }}
                        >
                          <div className="text-center">
                            <img 
                              style={imgStyle(prop.acronym)}
                              src={prop.image ? prop.image : cardImg(prop.acronym)}
                              alt="app-logo"
                            />
                          </div>
                          <CardBody>
                            <CardTitle className="text-center my-1">{prop.name}</CardTitle>
                          </CardBody>
                        </Card>
                      )
                  })) : (<h2> No Apps available </h2>) }
                </div>
              </CardBody>
            </Card>
          </Col>
          <AppFooter clss={"auth-footer-custom3"} />
        </Row>
        <Users />
        <AddUser />
      </>
    );
  };
  
  export default App;